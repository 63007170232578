import { Node } from "components/Drawer/NodeDrawer"
import NavigationSection from "components/Layout/Aside/NavigationSection"
import Link from "components/Link"

import MuiList from "@mui/material/List"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

export default function AsideNavigation({ node }: { node: Node }) {
    return (
        <Nav>
            {node.name ? (
                <Header>
                    <Link
                        href="#"
                        noWrap
                        sx={{ fontSize: 16, px: "14px" }}>
                        {node.name}
                    </Link>
                </Header>
            ) : null}
            <List>
                {node.nodes?.map(item => (
                    <NavigationSection
                        key={item.url}
                        {...item}
                    />
                ))}
            </List>
        </Nav>
    )
}

const Nav = styled("nav")(({ theme }) => ({
    overflowX: "hidden",
    "& > ul > li > a": {
        fontSize: theme.typography.body1.fontSize
    }
}))

const List = styled(MuiList)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    height: `calc(100vh - ${
        theme.constants.height.header +
        theme.constants.height.breadcrumbs +
        theme.constants.height.asideHeader
    }px)`,
    overflowY: "scroll"
}))

const Header = styled("span")(({ theme }) => ({
    alignItems: "center",
    backgroundColor: grey[300],
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1.5, 0)
}))
